// libs/components/src/lib/components/eventplatform/SearchInput/SearchForm.tsx
import React, { useState } from "react";
import SearchInput, {SearchInputProps} from "../search-input/SearchInput";
import styled from "@emotion/styled";


export interface SearchFormProps extends SearchInputProps {
    onSubmit: (value: string) => void;
}

const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
    width: "180px",
}));


/**
 * Search Form using the SearchInput component - the DS way.
 * used when we dont want to handle every input change - only the submit.
 */
export const SearchForm: React.FC<SearchFormProps> = (props: SearchFormProps) => {
    const { onSubmit } = props;
    const [value, setValue] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleReset = () => {
        setValue("");
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        onSubmit(value);
        event.preventDefault();
    };

    return (
        <form className="kms-ds-ui-search-form" onSubmit={handleSubmit}>
            <StyledSearchInput
                {...props}
                onInputChange={handleChange}
                onReset={handleReset}
            />
        </form>
    );
};

