import { DependencyList, useCallback, useEffect, useState } from "react";
import { useRaf } from "./useRaf";

/**
 * Get current time state that could be used for animation
 * (updates either each animationInterval milliseconds if specified or with each animation frame otherwise).
 *
 * The method returns the current time (in milliseconds).
 *
 * The optional callback will be called each time updating the time,
 * the first argument will be the time delta (in milliseconds).
 *
 *
 * Usage option 1 - use the result of the function for continuous animation.
 *
 *      const currentTime = useCurrentTimeForAnimation();
 *      const animationStyle = calculateAnimationStyleBasedOnCurrentTime(currentTime);
 *      return <div style={animationStyle}>{content}</div>;
 *
 *
 * Usage option 2 - use the delta function.
 *
 * In this case, useCurrentTimeForAnimation will be an analog of useInterval()
 * that will be called with an interval that is appropriate for animation.
 *
 *      const [currentTime, setCurrentTime] = useState(0);
 *      useCurrentTimeForAnimation((delta: number) => {
 *          const newCurrentTime = currentTime + delta;
 *          updateCurrentTime(newCurrentTime);
 *      }, 0, [...]);
 *
 * The second option is useful for complicated scenarios when just knowing the current time
 * is not enough to build the animation, e.g. when the timer could be paused.
 *
 * IMPORTANT:
 * Using this hook with a small (or empty) animationInterval value may hurt the page performance.
 * CSS animation is always preferred option over this hook.
 */
export function useCurrentTimeForAnimation(
    deltaCallback?: (delta: number) => void,
    animationInterval?: number,
    dependencies?: DependencyList
) {
    // "now" variable will be updated with the current time each animation frame
    const [now, setNow] = useState(() => Date.now());

    const tickHandler = useCallback(
        () => {
            if (!animationInterval) {
                return;
            }
            // Update the current time
            const newNow = Date.now();
            const delta = newNow - now;
            setNow(newNow);

            // Update the carousel animation time
            if (deltaCallback) {
                deltaCallback(delta);
            }
        },
        // Having "now" as a dependency will trigger the effect over and over again
        // disable eslint, dynamic dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [now, setNow, deltaCallback, animationInterval, ...(dependencies || [])]
    );
    useEffect(
        () => {
            if (animationInterval) {
                return;
            }
            const interval = setTimeout(tickHandler, animationInterval);

            return () => {
                clearTimeout(interval);
            };
        },
        // Having "now" as a dependency will trigger the effect over and over again
        [tickHandler, animationInterval]
    );
    // disable if animation interval is enabled
    useRaf(tickHandler, [tickHandler], !animationInterval);

    return now;
}
