import { DependencyList, useEffect } from "react";
import raf from "raf";

/**
 * custom hook to implement raf (request animation frame) call within function components.
 */
export function useRaf(
    callback: () => void,
    dependencies: DependencyList = [],
    disabled = false
) {
    useEffect(
        () => {
            if (disabled) {
                return;
            }
            const rafHandle = raf(callback);

            return () => {
                raf.cancel(rafHandle);
            };
        },
        // Having "now" as a dependency will trigger the effect over and over again
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [callback, disabled, ...dependencies]
    );
}
